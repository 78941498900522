import { message } from "antd";
import axios from "axios";
import { baseURL } from "../../utils/AppConstant";
import instance from "../../utils/helper/http.helper";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  ADD_TOKEN,
} from "./actionTypes";

const API_URL = "https://api.dunk.exchange";
const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (token) => {
  return {
    type: LOGIN_SUCCESS,
    token,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};
const saveToken = (token) => {
  return {
    type: ADD_TOKEN,
    token,
  };
};

export const userLogIn = (username, password) => async (dispatch) => {
  try {
    const response = await axios.post(`${baseURL}/auth/login`, {
      email: username,
      password: password,
    });
    localStorage.setItem("accessToken", response.data.accessToken);
    return response.data.accessToken;
    // dispatch(authentication(response.data.accessToken));
  } catch (error) {
    console.log("Login Error: ", error);
    dispatch(loginError());
    throw new Error();
  }
};

export const authentication = (token) => async (dispatch) => {
  debugger;
  dispatch(getTokenRedux());
  try {
    const res = await instance.get(`/auth/verify-jwt`);
    if (res.data.jwtVerified) {
      // localStorage.setItem("user", JSON.stringify(res.data));
      dispatch(receiveLogin(token));
    } else {
      dispatch(loginError());
    }
  } catch (error) {
    dispatch(loginError());
  }
};

export const userLogout = () => async (dispatch) => {
  // User Log Out Logic
  await dispatch(requestLogout());
  await localStorage.clear();
  await dispatch(receiveLogout());

  // window.location.reload();
};
export const getTokenRedux = () => async (dispatch) => {
  const token = await localStorage.getItem("accessToken");
  dispatch(saveToken(token));
};
export const getToken = async () => {
  const token = await localStorage.getItem("accessToken");
  return token;
};
export const verifyAuth = () => (dispatch) => {
  // Verify User on Reload Logic
  // dispatch(verifyRequest());
  // if (localStorage.getItem("accessToken")) {
  //   dispatch(receiveLogin());
  // }
  // dispatch(verifySuccess());
  dispatch(userLogout());
};
