import React, { useEffect, useState } from "react";
import styles from "./editBond.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import GenericButton from "../../_common/GenericButton";
import {
  BlocksRemainingTime,
  EditBondTerms,
  GetBondTerm,
} from "../../../services/bond.helper";
import { useActiveWeb3React } from "../../../services/WalletConnection/useWalletConnect";
import { message } from "antd";

const EditBond = () => {
  const history = useHistory();
  const { account } = useActiveWeb3React();
  const BOND_FIELDS_INDEXES = {
    vesting: 0,
    payout: 1,
    fee: 2,
    debt: 3,
    minPrice: 4,
    bvc: 5,
  };
  const { state } = useLocation();
  const [stateBond, setStateBond] = useState({
    vesting: 0,
    vestingLoading: false,
    bvc: 0,
    bvcLoading: false,
    fee: 0,
    feeLoading: false,
    debt: 0,
    debtLoading: false,
    payout: 0,
    payoutLoading: false,
    minPrice: 0,
    minPriceLoading: false,
  });
  const [vestingTime, setVestingTime] = useState("");
  useEffect(() => {
    fetchBondTerms();
  }, []);

  const onChangeHandler = async (e) => {
    const { name, value } = e.target;
    setStateBond((prev) => ({ ...prev, [name]: value }));
    if (name === "vesting") {
      const blocks = await BlocksRemainingTime(value);
      setVestingTime(blocks);
    }
  };
  const submitHandler = async (e) => {
    const { name } = e.target;
    e.preventDefault();
    if (!account) {
      message.error("First Connect to Wallet");
      return;
    }
    console.log("selected name", name);
    try {
      setStateBond((prev) => ({ ...prev, [name + "Loading"]: true }));
      const contactAddress = state?.data?.contractAddress;
      const index = BOND_FIELDS_INDEXES[name];
      const amount = stateBond[name];
      const res = await EditBondTerms(contactAddress, index, amount, account);
      setStateBond((prev) => ({ ...prev, [name + "Loading"]: false }));
      message.success("Updated Successfully");
    } catch (error) {
      console.log("error", error);
      setStateBond((prev) => ({ ...prev, [name + "Loading"]: false }));
      message.error("Something Went Wrong");
    }
  };
  const convertData = (data) => {
    const debt = Number(data.maxDebt) / 1e9;
    const cData = {
      maxDebt: debt.toLocaleString("fullwide", { useGrouping: false }),
      vestingTerm: data.vestingTerm,
      controlVariable: data.controlVariable,
      fee: data.fee / 100,
      maxPayout: data.maxPayout / 1000,
      minimumPrice: data.minimumPrice / 100,
    };
    return cData;
  };
  const fetchBondTerms = async () => {
    try {
      const res = await GetBondTerm(state?.data?.contractAddress);
      const data = convertData(res);
      const blocks = await BlocksRemainingTime(res?.vestingTerm);
      debugger;
      setVestingTime(blocks);
      setStateBond((prev) => ({
        ...prev,
        vesting: data.vestingTerm,
        bvc: data.controlVariable,
        fee: data.fee,
        debt: data.maxDebt,
        payout: data.maxPayout,
        minPrice: data.minimumPrice,
      }));
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{state?.data?.name}</div>
        <GenericButton
          name="Back"
          onClick={() => {
            history.goBack();
          }}
        />
      </div>

      <div className={styles.divider} />
      <div className={styles.container}>
        <label
          htmlFor="vesting"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          {` Vesting Blocks -  Approximate Time ( ${vestingTime} ) : `}
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter vesting rate"
              name="vesting"
              onChange={onChangeHandler}
              value={stateBond.vesting}
            />
          </div>
          <GenericButton
            isLoading={stateBond?.vestingLoading}
            name={"Submit"}
            inputName="vesting"
            onClick={submitHandler}
          />
        </div>
        <label
          htmlFor="payout"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          Max Payout (%) :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter payout "
              name="payout"
              onChange={onChangeHandler}
              value={stateBond.payout}
            />
          </div>
          <GenericButton
            isLoading={stateBond?.payoutLoading}
            name={"Submit"}
            inputName="payout"
            onClick={submitHandler}
          />
        </div>
        <label
          htmlFor="fee"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          Fee (%) :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter Fee"
              name="fee"
              onChange={onChangeHandler}
              value={stateBond.fee}
            />
          </div>
          <GenericButton
            isLoading={stateBond?.feeLoading}
            name={"Submit"}
            inputName="fee"
            onClick={submitHandler}
          />
        </div>
        <label
          htmlFor="debt"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          Total Debt (no. of ACX) :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter Debt rate"
              name="debt"
              onChange={onChangeHandler}
              value={stateBond.debt}
            />
          </div>
          <GenericButton
            isLoading={stateBond?.debtLoading}
            name={"Submit"}
            inputName="debt"
            onClick={submitHandler}
          />
        </div>
        <label
          htmlFor="minPrice"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          Bond Price :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter reward rate"
              name="minPrice"
              onChange={onChangeHandler}
              value={stateBond.minPrice}
            />
          </div>
          <GenericButton
            isLoading={stateBond?.minPriceLoading}
            name={"Submit"}
            inputName="minPrice"
            onClick={submitHandler}
          />
        </div>
        <label
          htmlFor="bvc"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          Control Variable :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="text"
              placeholder="Enter BVC rate"
              name="bvc"
              onChange={onChangeHandler}
              value={stateBond.bvc}
            />
          </div>
          <GenericButton
            isLoading={stateBond?.bvcLoading}
            name={"Submit"}
            inputName="bvc"
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default EditBond;
