import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
const INFURA_KEY = "3dfb5f4a4b564ee893955cd379a3e449";
export const RPC_URLS = {
  1: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  4: process.env.RPC_URL_4,
};
export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 97, 56, 4002],
});
export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1] },
  qrcode: true,
});
