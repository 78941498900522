import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  ACXStaked,
  circulatingSupply,
  rewardRate,
  setRewardRate,
  setWarmupPrice,
  totalSupplyACX,
  warmupPeriod,
} from "../../services/stake.helper";
import { useActiveWeb3React } from "../../services/WalletConnection/useWalletConnect";
import GenericButton from "../_common/GenericButton";
import styles from "./stake.module.scss";
import { useAlert } from "react-alert";

const Stake = () => {
  const alert = useAlert();
  const { account } = useActiveWeb3React();
  const [totalSupply, setTotalSupply] = useState("");
  const [circulatedSupply, setCirculatedSupply] = useState("");
  const [percentageStaked, setPercentageStaked] = useState("");
  const [warmup, setWarmup] = useState(0);
  const [reward, setReward] = useState(0);
  const [warmupLoading, setWarmupLoading] = useState(false);
  const [rewardLoading, setRewardLoading] = useState(false);

  const gridStyle = {
    width: "45%",
    textAlign: "center",
    backgroundColor: "white",
    borderRadius: "10px",
    margin: "10px",
    border: "none",
    color: "#004369",
    fontWeight: "500",
  };
  const gridLabel = {
    color: "green",
    fontWeight: "bold",
  };
  useEffect(() => {
    fetchRewardRate();
    fetchTotalSupplyACX();
    fetchCirculatingSupply();
    fetchACXStaked();
    fetchWarmupPeriod();
  }, []);

  const fetchTotalSupplyACX = async () => {
    try {
      const res = await totalSupplyACX();
      setTotalSupply(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchCirculatingSupply = async () => {
    try {
      const res = await circulatingSupply();
      setCirculatedSupply(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchACXStaked = async () => {
    try {
      const res = await ACXStaked();
      setPercentageStaked(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchWarmupPeriod = async () => {
    try {
      const res = await warmupPeriod();
      setWarmup(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  const fetchRewardRate = async () => {
    try {
      const res = await rewardRate();

      setReward(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const rewardHandler = async () => {
    try {
      if (!account) {
        message.error("First Connect to wallet");
        return;
      }
      setRewardLoading(true);
      debugger;
      const res = await setRewardRate(reward, account);
      setRewardLoading(false);
      message.success("Updated Successfully");
    } catch (error) {
      setRewardLoading(false);
      message.error("Something Went Wrong");
      console.log("error", error);
    }
  };
  const warmupHandler = async () => {
    try {
      if (!account) {
        message.error("First Connect to wallet");
        return;
      }
      setWarmupLoading(true);
      const res = await setWarmupPrice(warmup, account);
      setWarmupLoading(false);
      message.success("Updated Successfully");
    } catch (error) {
      setWarmupLoading(false);
      message.error("Something Went Wrong");
      console.log("error", error);
    }
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Stake</div>
      <div className={styles.divider} />
      <div className={styles.container}>
        <label
          htmlFor="rewardRate"
          className={styles.label}
          style={{ marginTop: "0px" }}
        >
          Reward Rate :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="number"
              placeholder="Enter reward rate"
              name="reward"
              value={reward && reward}
              onChange={(e) => setReward(e.target.value)}
            />
          </div>
          <GenericButton
            isLoading={rewardLoading}
            name={"Submit"}
            onClick={rewardHandler}
          />
        </div>
        <label htmlFor="warmupInfo" className={styles.label}>
          Warmup Period (Input should be an integer e.g (1,2,5,13...) :
        </label>
        <div className={styles.inputWrapper}>
          <div style={{ width: "100%" }}>
            <input
              type="number"
              placeholder="Enter warmup rate "
              value={warmup && warmup}
              name="warmup"
              onChange={(e) => setWarmup(e.target.value)}
            />
          </div>
          <GenericButton
            isLoading={warmupLoading}
            name={"Submit"}
            onClick={warmupHandler}
          />
        </div>
        <div className={styles.cardWrapper}>
          <Card>
            <Card.Grid style={gridStyle}>
              Total Supply : <span style={gridLabel}>{totalSupply}</span>
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              Circulating Supply :
              <span style={gridLabel}>{circulatedSupply}</span>
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              Percentage of ACX staked :
              <span style={gridLabel}>{percentageStaked ? `${percentageStaked}%`:"0%"}</span>
            </Card.Grid>
            {/* <Card.Grid style={gridStyle}>Content</Card.Grid> */}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Stake;
