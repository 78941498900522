import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Table } from "antd";
import React, { useState, useEffect } from "react";
import { BondList } from "../../services/bond.helper";
import { useActiveWeb3React } from "../../services/WalletConnection/useWalletConnect";
import GenericButton from "../_common/GenericButton";
import styles from "./bond.module.scss";
import { useHistory } from "react-router-dom";

const Bond = () => {
  const { chainId } = useActiveWeb3React();
  const history = useHistory();
  const [bondList, setBondList] = useState([]);
  const [bondData, setBondData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getBondList();
  }, [chainId]);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#090631" }} spin />
  );
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={antIcon} />,
  };

  const getBondList = async () => {
    try {
      setLoading(true);
      const data = await BondList();
      setBondList(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const editHandler = (e, data) => {
    e.preventDefault();
    history.push({
      pathname:  `/bond/${data.name}`,
      state: {
        data,
      },
    });
   
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price ( $ )",
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        return (
          <div style={{ width: "70px" }}>
             {Number(record?.price)?.toFixed(2) + " " + record?.priceName}
          </div>
        );
      },
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
    },
    {
      title: "Action",
      dataIndex: "edit",
      key: "edit",
      render: (text, record) => {
        return (
          <div style={{ width: "70px" }}>
            <GenericButton
              name="View"
              onClick={(e) => editHandler(e, record)}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Bond</div>
      <div className={styles.divider} />
      <div className={styles.container}>
        <Table
          dataSource={bondList}
          columns={columns}
          loading={loading ? tableLoading : false}
        />
      </div>
    </div>
  );
};

export default Bond;
