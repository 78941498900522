import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import Header from "../../../components/_common/Header/Header";
import Navigation from "../../../components/_common/Navigation/Navigation";
import styles from "./PrivateRoute.module.scss";
import { Layout, message } from "antd";
import useAuth from "../../../hooks/useAuth";
import { useActiveWeb3React } from "../../../services/WalletConnection/useWalletConnect";
import { NetworkTestChainId } from "../../../utils/contract";
const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { isAuthenticated, isVerifying} = useSelector((state)=>state.auth);
  const { chainId } = useActiveWeb3React();
  const { isAuthenticated, isVerifying } = useAuth();
  console.log("isAuthenticated", isAuthenticated);
  console.log("isVerifying", isVerifying);
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  // useEffect(() => {
  //   if (chainId && chainId !== NetworkTestChainId) {
  //     chainId && message.error("Wrong Network",0);
  //   }else{

  //   }
  // }, [chainId]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isVerifying ? (
          <div />
        ) : isAuthenticated ? (
          <>
            <Layout
              style={{
                minHeight: "100vh",
              }}
            >
              <Layout.Sider
                style={{ minHeight: "100vh" }}
                trigger={null}
                collapsible
                collapsed={collapsed}
                className={styles.sideLayout}
              >
                <div className="sidebarLogo">
                  <div
                    style={{
                      marginRight: collapsed ? "0px" : "10px",
                    }}
                  >
                    <img
                      src="/assets/acxlogos.svg"
                      alt="logo"
                      className={styles.logoImage}
                    />
                  </div>
                  <div
                    style={{
                      display: "block",
                      fontWeight: "500",
                      fontSize: "25px",
                      whiteSpace: "nowrap",
                      opacity: collapsed ? "0" : "1",
                      overflow: "hidden",
                      color: "white",
                    }}
                  >
                    ACX
                  </div>
                </div>
                <Navigation />
              </Layout.Sider>
              <Layout className="siteLayout">
                <Header collapsed={collapsed} toggle={toggle} />
                <Layout.Content
                  className="siteLayoutBackground"
                  style={{
                    margin: "24px 16px 16px 16px",
                    padding: 24,
                    minHeight: 280,
                  }}
                >
                  <Component {...props} />
                </Layout.Content>
              </Layout>
            </Layout>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
