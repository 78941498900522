import { message } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Slide, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import email from "../../assets/images/email.svg";
import eye from "../../assets/images/eye.svg";
import logo from "../../assets/images/loginLogo.png";
import { userLogIn } from "../../store/actions/authActions";
import instance from "../../utils/helper/http.helper";
import { userLoginSchema } from "../../validations/validations";
import styles from "./Login.module.scss";

const Login = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [wrongCred, setWrongCred] = useState(false);
  const dispatch = useDispatch();
  const [reveal, setReveal] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: userLoginSchema,
    onSubmit: async (values, onSubmitProps) => {
      if (values.password && values.email) {
        try {
          setIsLoading(true);
          setWrongCred(false);
          console.log("Login Form Values: ", values);
          const res = await dispatch(userLogIn(values.email, values.password));
          setIsLoading(false);
          history.push("/stake");
        } catch (error) {
          message.error("Can not access with these credentials");
          setWrongCred(true);
          setIsLoading(false);
        }
      } else {
        // console.log("returnig");
      }
    },
  });

  // TOAST FOR LOGIN//

  const notify = () => {
    toast("Succesfully Login");
  };
  // const [connectOpen, setConnectOpen] = useState(false);

  return (
    <>
      <ToastContainer />
      <div className={styles.wrapper}>
        <div className={styles.login}>
          <div className={styles.logo}>
            <img src="/assets/AcxLogo.svg" alt="logo" />
          </div>
          {/* <div className={styles.logoText}>DUNK</div> */}
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.inputDiv}>
              <label htmlFor="email">Email</label>
              <div className={styles.input}>
                <input type="email" {...formik.getFieldProps("email")} />
                <div className={styles.inputIcon}>
                  <img src={email} alt="mail" />
                </div>
              </div>
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className={styles.error}>{formik.errors.email}</div>
            ) : null}
            <div className={styles.inputDiv}>
              <label htmlFor="password">Password</label>
              <div className={styles.input}>
                <input
                  type={reveal ? "text" : "password"}
                  {...formik.getFieldProps("password")}
                />
                <div
                  className={styles.inputIcon}
                  onMouseDown={() => setReveal(true)}
                  onMouseUp={() => setReveal(false)}
                >
                  <img src={eye} alt="check" />
                </div>
              </div>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className={styles.error}>{formik.errors.password}</div>
            ) : null}
            {wrongCred && (
              <div className={styles.error}>Wrong credentials!</div>
            )}
            {/* <div className={styles.forgetWrapper}> */}
            {/* <div className={styles.forgot}>Forgot Your Password</div> */}
            {/* </div> */}

            {/* {!isConnected && (
              <div className={styles.error}>Connect your wallet first.</div>
            )} */}
            <button type="submit" className={styles.passwordButton}>
              {isLoading ? (
                <Loader type="Bars" color="#FFF" height={30} width={30} />
              ) : (
                "Login"
              )}
            </button>

            {/* <div className={styles.notWrapper}>
              <div className={styles.notHave}>
                <a href="https://senoa.me/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </div> */}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
