import Web3 from "web3";
import ABI from "../../utils/ABI/contract-api.json";
import staking_ABI from "../../utils/ABI/staking-contract-abi.json";
import sACX_ABI from "../../utils/ABI/SACX-contract-abi.json";
import BOND_ABI from "../../utils/ABI/BOND-abi.json";
import TREASURE_ABI from "../../utils/ABI/treasure-contract-abi.json";
import DISTRIBUTOR_ABI from "../../utils/ABI/distributor-contract-abi.json";
import { ACX_CONTRACT, BOND_CONTRACT, DISTRIBUTOR_CONTRACT, SACX_CONTRACT, STAKING_CONTRACT, TREASURE_CONTRACT } from "../../utils/contract";

function GetProvider() {
  // const { account } = useActiveWeb3React();
  // console.log("useWeb3React",account);
  const web3 = new Web3();
  web3.setProvider(window.web3.currentProvider);
  return web3;
}
export const GetsACXContract = async () => {
  try {
    // const contractAddress = "0xE6d41aC36506C28672E358737dfE499807Db3bac";
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ABI, ACX_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetStakingContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(staking_ABI, STAKING_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {

  }
};

export const GetsSACXContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(sACX_ABI, SACX_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {

  }
};
export const GetBondContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(BOND_ABI, BOND_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetTreasureContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(TREASURE_ABI, TREASURE_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetDistributorContract = async () => {
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(DISTRIBUTOR_ABI, DISTRIBUTOR_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
export const GetGenericBondContract = async (contractAddress) => {
  console.log("GetGenericBondContract",contractAddress);
  try {
    let web3 = GetProvider();

    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ABI, contractAddress);
      return MyContract;
    } else return null;
  } catch (error) {
    console.log("error in contract ", error);
  }
};
