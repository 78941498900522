import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import React from "react";

export default function ConfirmationModal({
  visible,
  toggleModal,
  onClickHandler,
  heading,
}) {
  return (
    <Modal
      title="Alert"
      visible={visible}
      onCancel={toggleModal}
      footer={[
        <div style={{ display: "flex" }}>
          <Button key="back" type="primary" onClick={toggleModal}>
            Cancel
          </Button>
          <Button key="submit" type="danger" onClick={onClickHandler}>
            Confirm
          </Button>
        </div>,
      ]}
    >
      <h3>{heading}</h3>
    </Modal>
  );
}
