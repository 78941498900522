import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import Login from "./components/Login/Login";

import PrivateRoute from "./components/_common/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/_common/PublicRoute/PublicRoute";
import Settings from "./components/Settings/Settings";
import Bond from "./components/bond";
import Stake from "./components/Stake";
import EditBond from "./components/bond/EditBond";
import { message } from "antd";
import { Route, Redirect } from "react-router-dom";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <PublicRoute exact path="/" component={Login} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/bond" component={Bond} />
          <PrivateRoute exact path="/bond/:id" component={EditBond} />
          <PrivateRoute exact path="/stake" component={Stake} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
