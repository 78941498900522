import { BUSD_CONTRACT } from "../utils/contract";
import { GetBondContract, GetsACXContract, GetsSACXContract, GetTreasureContract } from "./Contract/contract.helper";

export const getTokenValueInUSD = async (address, decimals) => {
    const dec = Math.pow(10, Number(decimals));
    try {
      const customToken = await GetBondContract();
      const tokenAmountETH =
        (await customToken.methods.bondPriceInUSD(address).call()) / dec;
  
      return tokenAmountETH.toFixed(2);
    } catch (error) {
      console.log("error", error);
      throw new Error();
    }
  };
  export const getTokenValue = async (address, decimal) => {
    const customToken = await GetBondContract();
    const customPrice = async (decimal) => {
      
      const customTokenTreasure = await GetTreasureContract();
      const treasureValue = await customTokenTreasure.methods
        .valueOf(address, decimal)
        .call();
      const tokenVal = await customToken.methods
        .payoutFor(address, treasureValue)
        .call();
      const LP = Number(tokenVal);
      const res = 1e9 / Number(LP);
      return Number(res).toFixed(9);
    };
    try {
      const customToken = await GetBondContract();
      const { isLiquidityBond } = await customToken.methods
        .BondTerms(address)
        .call();
      if (isLiquidityBond) {
        const res = await customPrice("1000000000000000000");
        return res;
      } else {
        if (address === BUSD_CONTRACT) {
          const tokenAmountETH = await customToken.methods
            .bondPrice(address)
            .call();
          const data = tokenAmountETH / 100;
          const res = data;
          return res;
        } else {
          const dec = Math.pow(10, Number(decimal));
          const res = await customPrice(dec);
          return res;
        }
      }
    } catch (error) {
      console.log("error", error);
      throw new Error();
    }
  };
  export const getTokenBalance = async (account) => {
    const customToken = await GetsACXContract();
    const tokenAmountETH = await customToken.methods.balanceOf(account).call();
    const tokenValue = tokenAmountETH / 1e9;
    return tokenValue;
  };
  export const getSACXTokenBalance = async (account) => {
    const customToken = await GetsSACXContract();
    const tokenAmountETH = await customToken.methods.balanceOf(account).call();
    const tokenValue = tokenAmountETH / 1e9;
    return tokenValue;
  };