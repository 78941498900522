import React, { useState } from "react";
import { Menu } from "antd";
import {
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
  GiftOutlined,
  OrderedListOutlined,
  SettingOutlined,
  AreaChartOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../store/actions/authActions";
import styles from "./Navigation.module.scss";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
const Navigation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const toggleModal = () => {
    setToggle(!toggle);
    renderLocation();
  };
  const location = useLocation();

  const renderLocation = () => {
    switch (location.pathname) {
      case "/settings":
        return ["2"];
      case "/bond":
        return ["3"];
      case "/stake":
        return ["4"];
      default:
        return ["1"];
    }
  };
  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={renderLocation()}
      className={styles.navigationSide}
    >
      <Menu.Item
        key="4"
        icon={<AreaChartOutlined />}
        className={styles.menuItem}
      >
        <Link to="/stake">Stake</Link>
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<PieChartOutlined />}
        className={styles.menuItem}
      >
        <Link to="/bond">Bond</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<SettingOutlined />} className={styles.menuItem}>
        <Link to="/settings">Settings</Link>
      </Menu.Item>

      <Menu.Divider className={styles.divider} />
      <Menu.Item key="1" icon={<LogoutOutlined />} className={styles.menuItem} onClick={toggleModal}>
       <>Logout</> 
      </Menu.Item>
      <ConfirmationModal
        visible={toggle}
        toggleModal={toggleModal}
        onClickHandler={() => {
          toggleModal();
          dispatch(userLogout());
          history.push("/");
        }}
        heading="Are you sure you want to Logout ?"
      />
    </Menu>
  );
};

export default Navigation;
