import { STAKING_CONTRACT } from "../utils/contract";
import {
  GetDistributorContract,
  GetsACXContract,
  GetsSACXContract,
  GetStakingContract,
} from "./Contract/contract.helper";

export const totalSupplyACX = async () => {
  try {
    const customToken = await GetsACXContract();
    const res1 = await customToken.methods.totalSupply().call();
    const res = res1 / 1e9;
    return res.toFixed(2);
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const circulatingSupply = async () => {
  try {
    const customToken = await GetsSACXContract();
    const res1 = await customToken.methods.circulatingSupply().call();
    const res = res1 / 1e9;
    return res.toFixed(2);
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const ACXStaked = async () => {
  try {
    const cirSupply = await circulatingSupply();
    const acxSupply = await totalSupplyACX();
    const pecentageACX = cirSupply / acxSupply;
    const res = pecentageACX * 100;
    return res.toFixed(2);
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const warmupPeriod = async () => {
  try {
    const customToken = await GetStakingContract();
    const res = await customToken.methods.warmupPeriod().call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const setWarmupPrice = async (amount, account) => {
  try {
    debugger;
    const customToken = await GetStakingContract();
    const res = await customToken.methods
      .setWarmup(amount)
      .send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const rewardRate = async () => {
  try {
    const customToken = await GetDistributorContract();
    const length = await customToken.methods.infoLength().call();
    const data = await customToken.methods.info(length - 1).call();
    const res = data?.rate / 10000;
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const setRewardRate = async (rewardAmount, account) => {
  try {
    debugger;
    if (!rewardAmount) throw new Error();
    const amount = rewardAmount * 10000;
    const customToken = await GetDistributorContract();
    const length = await customToken.methods.infoLength().call();
    const length2 = length - 1;
    const remove = await customToken.methods
      .removeRecipient(length2.toString(), STAKING_CONTRACT)
      .send({ from: account });
    const add = await customToken.methods
      .addRecipient(STAKING_CONTRACT, amount)
      .send({ from: account });
    return;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
