// First we need to import axios.js
import axios from "axios";
import { store } from "../../index";
// Next we make an 'instance' of it
const instance = axios.create({
  timeout: 20000, // request timeout
  // baseURL: process.env.REACT_APP_BASE_URL
  // baseURL: 'http://localhost:3001/api'
  baseURL: "https://staging-api.acxd.io",
});

// request interceptor

instance.interceptors.request.use(
  (config) => {
    const {
      auth: { authToken },
    } = store.getState();
    // Do something before request is sent
    // config.headers.common["x-auth-token"] = `${localStorage.getItem('accessToken')}`;

    config.headers["Authorization"] = "bearer " + localStorage.getItem('accessToken');
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (
      error.response.status >= 400 &&
      error.response.status < 500 &&
      error.response.status != 409
    ) {
      localStorage.clear();
    }

    return Promise.reject(error);
  }
);
// Where you would set stuff like your 'Authorization' header, etc ...
// instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN FROM INSTANCE';

// Also add/ configure interceptors && all the other cool stuff

export default instance;
