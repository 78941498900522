import { message } from "antd";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import instance from "../utils/helper/http.helper";

export default function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVerifying, setIsVerifying] = useState(true);
  const verifyAuth = () => {
    instance
      .get(`/auth/verify-jwt`)
      .then(function (response) {
        console.log(response.data);
        if (response.data.jwtVerified) {
          setIsAuthenticated(true);
          setIsVerifying(false);
          return;
        }
        setIsAuthenticated(false);
        setIsVerifying(false);
        message.info("Can not access with user role");
      })
      .catch(function (error) {
        setIsAuthenticated(false);
        setIsVerifying(false);
      });
  };
  const notify = () => {
    toast("Can not access with user role");
  };

  useEffect(() => {
    verifyAuth();
  }, []);
  return { isAuthenticated, isVerifying };
}
