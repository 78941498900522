import React from "react";
import { Route, Redirect } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useSelector } from "react-redux";
const PublicRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isVerifying } = useAuth();
  // const { isAuthenticated, isVerifying} = useSelector((state)=>state.auth);
  console.log("isAuthenticated", isAuthenticated);
  console.log("isVerifying", isVerifying);

  return (
    <Route
      {...rest}
      render={(props) =>
        isVerifying ? (
          <div />
        ) : !isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/stake",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
