import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import styles from "./Header.module.scss";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import { injected } from "../../../services/WalletConnection/WalletConnector";
import { useActiveWeb3React } from "../../../services/WalletConnection/useWalletConnect";
import { changeNetwork } from "../../../services/WalletConnection/changeNetwork";
import { NetworkTest } from "../../../utils/contract";

const Header = ({ collapsed, toggle }) => {
  const [connectOpen, setConnectOpen] = useState(false);
  const [error, setError] = useState();
  const {
    active,
    account,
    library,
    connector,
    activate,
    deactivate,
    chainId
  } = useActiveWeb3React();
  useEffect(() => {
    handleNetworkSwitch();
  }, [account, chainId]);

  const connectWallet = async () => {
    if (window.ethereum === undefined) {
      window
        .open(
          "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
          "_blank"
        )
        .focus();
      return;
    }

    try {
      await activate(injected);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleNetworkSwitch = async () => {
    debugger;
    try {
      setError();
      await changeNetwork({ networkName: NetworkTest, setError });
    } catch (e) {
      console.log(error);
    }
  };

  return (
    <Layout.Header className={styles.header} style={{ padding: 0 }}>
      {collapsed ? (
        <MenuUnfoldOutlined className={styles.trigger} onClick={toggle} />
      ) : (
        <MenuFoldOutlined className={styles.trigger} onClick={toggle} />
      )}

      <div className={styles.buttonDiv} onClick={connectWallet}>
        {/* disabled={isLoading} */}
        <button>
          {false ? (
            <Loader type="Bars" color="#FFF" height={15} />
          ) : (
            <>
              {account
                ? `${account.substring(0, 6)}...${account.substring(
                    account.length - 4
                  )}`
                : " Connect Wallet"}
            </>
          )}
        </button>
      </div>
    </Layout.Header>
  );
};

export default Header;
