// ******************************************************************************

//Ropsten Testnet:
// export const ACX_CONTRACT="0x10B229C2771c75485BdE87273354FddF11aA5902";
// export const SACX_CONTRACT="0x1B16Bc6f4E157707154b7cFB4aFA1b0E73970dB9";
// export const STAKING_CONTRACT="0x283d1793a5159171e275962254C2D3B198ebd76D";
// export const BOND_CONTRACT="0xfC43bB4Cf2ABb48d1770f553F938A8193115055a";
// export const BUSD_CONTRACT="0xc4973618E2b0f88e2C5b9490121AAA1CF578A832";
// export const TREASURE_CONTRACT="0xA13eE7162b4484c6268e3102434baF8d2C9cf517";
// export const DISTRIBUTOR_CONTRACT="0xEa436d537C62d478571AC15C10F0995680A6Fb17";

//************************************************************************************* */

//FantomTestnet :

// export const ACX_CONTRACT="0xC85f3b514Ce5a1885B275392Bb9344354a070c60";
// export const SACX_CONTRACT="0xF75c782A4a05490a3d69755A2be00998230517ba";
// export const STAKING_CONTRACT="0xe824Fc04928c0A6E63838c3f6375d68A974b2dc1";
// export const BOND_CONTRACT="0x85B7688933b86Ac5041Bd6d8459C3F496370fB1f";
// export const BUSD_CONTRACT="0xd53a7C74a14068839607e807e7EBAf7c3e8F221F";
// export const TREASURE_CONTRACT="0xdA57963bd5E1ACEF5B7C734c8227ac1847EC32e8";
// export const DISTRIBUTOR_CONTRACT="0x5c1b3f5521948692A76F756094301B5A7B571c8d";

// //Change Network Name
// export const NetworkTest = "FTMTest";
// export const NetworkMain = "FTM";
// //Change ChainId
// export const NetworkMainChainId = "";
// export const NetworkTestChainId = 4002;

//************************************************************************************* */

//BinanceTestnet :
export const ACX_CONTRACT = "0xC85f3b514Ce5a1885B275392Bb9344354a070c60";
export const SACX_CONTRACT = "0xF75c782A4a05490a3d69755A2be00998230517ba";
export const STAKING_CONTRACT = "0xe824Fc04928c0A6E63838c3f6375d68A974b2dc1";
export const BOND_CONTRACT = "0xC097f797d3edB2aAb90Aa696aA2c0D9378232de0";
export const BUSD_CONTRACT = "0xd53a7C74a14068839607e807e7EBAf7c3e8F221F";
export const TREASURE_CONTRACT = "0x4F8A37FAc6aF46b2856161e7A32dfa0185E9f1C3";
export const DISTRIBUTOR_CONTRACT =
  "0x7c0b44ABF210ad7016dfF1ce998C39FF3dD86D37";

//Change Network Name
export const NetworkTest = "BSCTest";
export const NetworkMain = "BSC";
//Change ChainId
export const NetworkMainChainId = "";
export const NetworkTestChainId = 97;

//************************************************************************************* */
