import * as yup from "yup";

export const userLoginSchema = yup.object().shape({
  email: yup.string().required("Email is field required"),
  password: yup.string().required("Password field is required"),
});


export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password field is required"),
  password: yup
    .string()
    .min(8, "Min Password Length is 8")
    .required("Password field is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password don't match!")
    .required("Confirm password field is required"),
});

export const featuredSchema = yup.object().shape({
  title: yup
    .string()
    .min(10, "Min title Length is 10")
    .max(40, "Max title Length is 20")
    .required("Title is field required."),
  shortDescription: yup
    .string()
    .min(200, "Min Content Length is 200")
    .max(700, "Max content length is 400")
    .required("Short description field is required."),
});
