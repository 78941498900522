import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./custom-antd.less";
import { Provider as AlertProvider, positions, transitions } from "react-alert";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configureStore";
import AlertTemplate from "./components/AlertTemplate/AlertTemplate";
import "./global.scss";
import { createWeb3ReactRoot, Web3ReactProvider } from "@web3-react/core";
import Web3ReactManager from "./services/WalletConnection/Web3ReactManager";
import Web3 from "web3";

export const store = configureStore();
const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};
const Web3ProviderNetwork = createWeb3ReactRoot("Network");
function getLibrary(provider) {
  return new Web3(provider);
}
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <AlertProvider template={AlertTemplate} {...options}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <Web3ProviderNetwork getLibrary={getLibrary}>
            <Web3ReactManager>
              <App />
            </Web3ReactManager>
          </Web3ProviderNetwork>
        </Web3ReactProvider>
      </AlertProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
