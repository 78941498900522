import { Switch, DatePicker, message } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import moment from "moment";
import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import instance from "../../utils/helper/http.helper";
import styles from "./Settings.module.scss";
const initialValues = {
  siteDescription: "",
  contactEmail: "",
  telegramUrl: "",
  twitterUrl: "",
  facebookUrl: "",
  redditUrl: "",
  whitePaperUrl: "",
  lightPaperUrl: "",
  copyRight: "",
  maintenanceMode: true,
  preSaleStartDate: new Date(),
  preSaleEndDate: new Date(),
};
export default function Settings() {
  function onChange(checked) {
    formik.setFieldValue("maintenanceMode", checked);
  }
  const onchangeDate = (value, value2) => {
    formik.setFieldValue("preSaleStartDate", value2[0]);
    formik.setFieldValue("preSaleEndDate", value2[1]);
  };
  const [state, setState] = useState(initialValues);
  const getSettings = async () => {
    const res = await instance
    .get("/settings");

    const { createdAt, updatedAt, __v, _id, ...rest } = res.data;
    console.log(rest);
    setState((prev) => ({ ...prev, ...rest }));
  };
  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY/MM/DD";
  useEffect(() => {
    getSettings();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: state,
    enableReinitialize: true,
    // validationSchema: userLoginSchema,
    onSubmit: async (values, onSubmitProps) => {
      try {
        setIsLoading(true);
        console.log("Onsubmit", values);
        const res = await instance.put(`/settings`, {
          ...values,
        });
        setIsLoading(false);
        message.success("Updated Successfully");
      } catch (error) {
        message.error("Something Went Wrong");
        setIsLoading(false);
      }
    },
  });
  console.log("state", state);
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Settings</div>
      <div className={styles.divider} />
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        {
          <>
            <label htmlFor="siteDescription" className={styles.label}>
              Site Description :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write siteDescription"
                {...formik.getFieldProps("siteDescription")}
              />
              {formik.touched.siteDescription &&
              formik.errors.siteDescription ? (
                <div className={styles.error}>
                  {formik.errors.siteDescription}
                </div>
              ) : null}
            </div>
            <label htmlFor="contactEmail" className={styles.label}>
              Contact Email :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write contactEmail"
                {...formik.getFieldProps("contactEmail")}
              />
              {formik.touched.contactEmail && formik.errors.contactEmail ? (
                <div className={styles.error}>{formik.errors.contactEmail}</div>
              ) : null}
            </div>
            <label htmlFor="telegramUrl" className={styles.label}>
              Telegram Url :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write telegramUrl"
                {...formik.getFieldProps("telegramUrl")}
              />
              {formik.touched.telegramUrl && formik.errors.telegramUrl ? (
                <div className={styles.error}>{formik.errors.telegramUrl}</div>
              ) : null}
            </div>
            <label htmlFor="twitterUrl" className={styles.label}>
              Twitter Url :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write twitterUrl"
                {...formik.getFieldProps("twitterUrl")}
              />
              {formik.touched.twitterUrl && formik.errors.twitterUrl ? (
                <div className={styles.error}>{formik.errors.twitterUrl}</div>
              ) : null}
            </div>
            <label htmlFor="facebookUrl" className={styles.label}>
              Facebook Url :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write facebookUrl"
                {...formik.getFieldProps("facebookUrl")}
              />
              {formik.touched.facebookUrl && formik.errors.facebookUrl ? (
                <div className={styles.error}>{formik.errors.facebookUrl}</div>
              ) : null}
            </div>
            <label htmlFor="redditUrl" className={styles.label}>
              Reddit Url :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write redditUrl"
                {...formik.getFieldProps("redditUrl")}
              />
              {formik.touched.redditUrl && formik.errors.redditUrl ? (
                <div className={styles.error}>{formik.errors.redditUrl}</div>
              ) : null}
            </div>
            <label htmlFor="whitePaperUrl" className={styles.label}>
              White Paper Url :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write whitePaperUrl"
                {...formik.getFieldProps("whitePaperUrl")}
              />
              {formik.touched.whitePaperUrl && formik.errors.whitePaperUrl ? (
                <div className={styles.error}>
                  {formik.errors.whitePaperUrl}
                </div>
              ) : null}
            </div>
            <label htmlFor="lightPaperUrl" className={styles.label}>
              Light Paper Url :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write lightPaperUrl"
                {...formik.getFieldProps("lightPaperUrl")}
              />
              {formik.touched.lightPaperUrl && formik.errors.lightPaperUrl ? (
                <div className={styles.error}>
                  {formik.errors.lightPaperUrl}
                </div>
              ) : null}
            </div>
            <label htmlFor="copyRight" className={styles.label}>
              Copy Right :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Write copyRight"
                {...formik.getFieldProps("copyRight")}
              />
              {formik.touched.copyRight && formik.errors.copyRight ? (
                <div className={styles.error}>{formik.errors.copyRight}</div>
              ) : null}
            </div>

            <label htmlFor="maintenanceMode" className={styles.label}>
              Maintenance Mode :
            </label>
            <Switch
              checked={formik.values.maintenanceMode}
              onChange={onChange}
            />
            <label htmlFor="coinName" className={styles.label}>
              Pre Sale Date :
            </label>
            <div style={{ marginBottom: "20px" }}>
              <RangePicker
                defaultValue={[
                  moment(formik.values.preSaleStartDate, dateFormat),
                  moment(formik.values.preSaleEndDate, dateFormat),
                ]}
                value={[
                  moment(formik.values.preSaleStartDate, dateFormat),
                  moment(formik.values.preSaleEndDate, dateFormat),
                ]}
                format={dateFormat}
                onChange={onchangeDate}
              />
              {formik.touched.totalSupply && formik.errors.totalSupply ? (
                <div className={styles.error}>{formik.errors.totalSupply}</div>
              ) : null}
            </div>

            <div className={styles.buttonDiv}>
              <button disabled={isLoading} type="submit">
                {/* <PlusOutlined /> */}
                {isLoading ? (
                  <Loader type="Bars" color="#FFF" height={30} width={30} />
                ) : (
                  <span>Save</span>
                )}
              </button>
            </div>
          </>
        }
      </form>
    </div>
  );
}
