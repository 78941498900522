import { message } from "antd";
import React from "react";
import Loader from "react-loader-spinner";
import styles from "./genericButton.module.scss";

const GenericButton = ({ isLoading, name, onClick, inputName }) => {
  return (
    <div className={styles.buttonDiv} >
      <button disabled={isLoading} name={inputName} onClick={onClick}>
        {/* <PlusOutlined /> */}
        {isLoading ? (
          <>
            <Loader type="Bars" color="#FFF" height={30} width={30} />
          </>
        ) : (
          <>{name}</>
        )}
      </button>
    </div>
  );
};

export default GenericButton;
