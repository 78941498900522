import { BLOCKS_PER_DAY } from "../utils/AppConstant";
import { ACX_CONTRACT } from "../utils/contract";
import {
  GetBondContract,
  GetGenericBondContract,
} from "./Contract/contract.helper";
import { getTokenValueInUSD } from "./token.helper";

export const BondList = async () => {
  try {
    const customTokenBond = await GetBondContract();
    const bondCount = await customTokenBond.methods.bondsCount().call();
    let bondList = [];
    if (bondCount) {
      for (let i = 0; i < bondCount; i++) {
        const bondData = await customTokenBond.methods.BondsList(i).call();
        if (bondData) {
          const { isLiquidityBond } = await customTokenBond.methods
            .BondTerms(bondData)
            .call();
          const customToken = await GetGenericBondContract(bondData);

          let name = "";
          let decimalUSD;
          let priceName = "$";
          if (isLiquidityBond) {
            const Token0 = await customToken.methods.token0().call();
            const customTokenLP0 = await GetGenericBondContract(Token0);
            const name1 = await customTokenLP0.methods.name().call();
            const Token1 = await customToken.methods.token1().call();
            const customTokenLP1 = await GetGenericBondContract(Token1);
            const name2 = await customTokenLP1.methods.name().call();
            name = `${name1}-${name2}`;
            const nonACXToken = ACX_CONTRACT === Token0 ? Token1 : Token0;
            const customTokenNonACX = await GetGenericBondContract(nonACXToken);
            priceName = await customTokenNonACX.methods.name().call();
            decimalUSD = await customTokenNonACX.methods.decimals().call();
          } else {
            name = await customToken.methods.name().call();
            decimalUSD = await customToken.methods.decimals().call();
          }
          const   decimals = await customToken.methods.decimals().call();
          const price = await getTokenValueInUSD(bondData, decimalUSD);
          console.log("decimals", decimals);
          console.log("price", price);
          console.log("priceName", priceName);
          if (price) {
            bondList.push({
              name: name,
              contractAddress: bondData,
              price: price,
              decimals: decimals,
              priceName
            });
          }
        }
      }
    }
    return bondList;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const GetBondTerm = async (address) => {
  try {
    const customTokenBond = await GetBondContract();
    const res = await customTokenBond.methods.BondTerms(address).call();
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const EditBondTerms = async (contactAddress, index, amount, account) => {
  console.log("ADDRESS", contactAddress);
  console.log("Index in submit", index);
  console.log("Input", amount);
  if (!amount) throw new Error();
  try {
    let value = "";
    if (index === 0) {
      value = amount;
    } else if (index === 1) {
      value = amount * 1000;
    } else if (index === 2) {
      value = amount * 100;
    } else if (index === 3) {
      const number = amount * 1e9;
      value = number.toLocaleString("fullwide", { useGrouping: false });
    } else if (index === 4) {
      value = amount * 100;
    } else if (index === 5) {
      value = amount;
    }
    console.log("value ", value);
    const customTokenBond = await GetBondContract();
    debugger;
    const res = await customTokenBond.methods
      .setBondTerms(contactAddress, index, value)
      .send({ from: account });
    return res;
  } catch (error) {
    console.log("error", error);
    throw new Error();
  }
};
export const BlocksRemainingTime = async (blocks) => {
  const blockLeft = blocks;
  if (blockLeft > 0) {
    const blocksPerHr = BLOCKS_PER_DAY / 24;
    const hoursPerBlock = 1 / blocksPerHr;
    const hoursLeft = blockLeft * hoursPerBlock;
    let res = hoursLeft;
    if (hoursLeft > 24) {
      const days = (hoursLeft / 24)?.toFixed(0);
      res = `${days} days`;
      return res;
    } else if (hoursLeft < 1) {
      const mins = (hoursLeft * 60)?.toFixed(0);
      res = `${mins} minutes`;
      return res;
    }
    return `${res?.toFixed(0)} hrs`;
  } else {
    return "0 min";
  }
};
